// import React from "react";
const ScheduleBlock = (props: any) => {
  const { block } = props;

  return (
    <div
      className={block.className + " draggable z-0 bg-green-200 opacity-60"}
      id={block.id}
      style={{
        width: `${block.width}px`,
        height: block.height,
        // backgroundColor: block.backgroundColor,
        border: block.border,
        position: block.position,

        borderRadius: "5px",
        fontSize: ".8em",
        overflow: "hidden",
        filter: "drop-shadow(-2px 2px 2px rgba(0,0,0,.5))",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        paddingLeft: "2px",
      }}
    >
      {props.children}
    </div>
  );
};
export default ScheduleBlock;
