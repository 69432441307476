import { SubmitHandler, useForm } from "react-hook-form";
import Service from "../../../types/Service";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useDeleteOrganizationService,
  useLoadOrganizationServiceById,
  useMutateOrganizationService,
} from "../../../api/service";
import { useEffect } from "react";

export type ServiceEditorProps = {
  service?: Service | undefined;
};

export default function ServiceEditor(props: ServiceEditorProps) {
  const { orgId } = useParams();
  const { mutate, error } = useMutateOrganizationService();
  const { mutate: deleteSvc } = useDeleteOrganizationService();
  let service = props.service;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Service>({
    values: service
      ? {
          ...service,
        }
      : undefined,
  });

  const onSubmit: SubmitHandler<Service> = (data) => {
    data.organization_id = orgId ? orgId : "";
    mutate(data);
    // close();
    //reset form
    reset();
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  //   const sId = searchParams.get("sId");

  //   let { data: service } = useLoadOrganizationServiceById(sId ? sId : "");

  const deleteService = (sId: string) => {
    deleteSvc(sId);
    close();
  };

  const close = () => {
    setSearchParams({ ...params, action: "", sId: "" });
    reset();
  };

  useEffect(() => {
    if (!service || JSON.stringify(service) == "") {
      reset();
    }
  }, [service]);
  //   useEffect(() => {
  //     if (!sId || sId == "") {
  //       reset();
  //     }
  //   }, [sId]);

  return (
    <div className="flex flex-col p-4 px-10">
      <div className="text-2xl text-gray-400 pb-4 pt-6 border  border-t-0 border-x-0 border-b-1 border-b-gray-100">
        Service Editor
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-controls">
        <div className="flex flex-col pt-6">
          <div className="flex flex-col">
            <label>NAME</label>
            <input
              {...register("name", {
                required: true,
                minLength: 3,
                maxLength: 100,
              })}
              type="text"
              placeholder="Service Name"
              className="input input-bordered w-full max-w-96"
            />
          </div>
          <div className="flex flex-col pt-4">
            <label>PRICE</label>
            <input
              {...register("price", {
                required: true,

                max: 9999,
                validate: (value, formValues) => {
                  var RE = /^-{0,1}\d*\.{0,1}\d+$/;
                  return RE.test(value.toString());
                },
              })}
              type="text"
              placeholder="Price"
              className="input input-bordered w-full max-w-96"
            />
          </div>
          <div className="flex flex-col pt-4">
            <label>DESCRIPTIONS</label>
            <textarea
              {...register("description", {
                minLength: 3,
                maxLength: 100,
              })}
              placeholder="Description"
              className="input input-bordered w-full h-20"
            />
          </div>
          <div className="flex flex-row justify-end">
            <div className="m-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  close();
                }}
              >
                Cancel
              </button>
            </div>
            {service && (
              <div className="m-2">
                <button
                  type="button"
                  onClick={() => {
                    deleteService(service.id);
                  }}
                  className="btn btn-warning"
                >
                  DELETE
                </button>
              </div>
            )}
            <div className="m-2">
              <button type="submit" className="btn btn-primary">
                SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
