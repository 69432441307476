// import * as React from "react";
import "overlayscrollbars/overlayscrollbars.css";
import { useEffect, useRef, useState } from "react";
import { useOverlayScrollbars } from "overlayscrollbars-react";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import moment from "moment";

import "./scheduler.css";
import ResourcePanel from "./resourcePanel";

import SchedulerPanel from "./schedulerPanel";
import { useSearchParams } from "react-router-dom";
import {
  format,
  toDate,
  add,
  isValid,
  isAfter,
  isBefore,
  getHours,
} from "date-fns";
import SchedulerService from "./SchedulerService";
import ScheduleEditor from "./ScheduleEditor";

export default function Scheduler(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = Object.fromEntries([...searchParams]);

  const dt = searchParams.get("dt");
  const editor = searchParams.get("editor");

  let currentDate = new Date();
  if (dt && dt.length > 0) {
    if (!isValid(dt)) {
      currentDate = toDate(dt);
    }
  } else {
    currentDate = new Date();
  }

  const toggleEditor = () => {
    setSearchParams({ ...params, editor: editor && editor == "1" ? "" : "1" });
  };
  const [currentSelectedDate, setCurrentSelectedDate] = useState(currentDate);
  const onAddOrUpdateAppointment = (appointment: any) => {};

  const onDeleteAppointment = (appointmentId: string) => {};

  const onDeleteAppointmentService = (serviceId: string) => {};
  const appts: any = [];
  const resList: any = [
    { id: 1, first_name: "Mark", last_name: "Tran" },
    { id: 2, first_name: "John", last_name: "Doe" },
    { id: 3, first_name: "Mark", last_name: "Tran" },
    { id: 4, first_name: "John", last_name: "Doe" },
    { id: 5, first_name: "Mark", last_name: "Tran" },
    { id: 6, first_name: "John", last_name: "Doe" },
    { id: 7, first_name: "Mark", last_name: "Tran" },
    { id: 8, first_name: "John", last_name: "Doe" },
    { id: 9, first_name: "Mark", last_name: "Tran" },
    { id: 10, first_name: "John", last_name: "Doe" },
    { id: 11, first_name: "Mark", last_name: "Tran" },
    { id: 12, first_name: "John", last_name: "Doe" },
    { id: 13, first_name: "Mark", last_name: "Tran" },
    { id: 14, first_name: "John", last_name: "Doe" },
    { id: 15, first_name: "Mark", last_name: "Tran" },
    { id: 16, first_name: "John", last_name: "Doe" },
    { id: 17, first_name: "Mark", last_name: "Tran" },
    { id: 18, first_name: "John", last_name: "Doe" },
    { id: 19, first_name: "Mark", last_name: "Tran" },
    { id: 20, first_name: "John", last_name: "Doe" },
    { id: 21, first_name: "Mark", last_name: "Tran" },
    { id: 22, first_name: "John", last_name: "Doe" },
    { id: 23, first_name: "Mark", last_name: "Tran" },
    { id: 24, first_name: "John", last_name: "Doe" },
    { id: 25, first_name: "Mark", last_name: "Tran" },
    { id: 26, first_name: "John", last_name: "Doe" },
    { id: 27, first_name: "Mark", last_name: "Tran" },
    { id: 28, first_name: "John", last_name: "Doe" },
  ];

  const ref = useRef<any>();

  const onCurrentDateChange = (currentDate: any) => {
    if (
      format(currentDate, "MM/dd/yyyy") !==
      format(currentSelectedDate, "MM/dd/yyyy") //.format("YYYY-MM-DD")
    ) {
      setCurrentSelectedDate(currentDate);
      //change URL params
      setSearchParams({ ...params, dt: format(currentDate, "MM/dd/yyyy") });
    }
  };

  // const [currentDate, setCurrentDate] = useState<any>(moment());
  const [scrollTo, setScrollTo] = useState<number>(0);
  const [scrollReady, setScrollReady] = useState(false);
  const [initialize, instance] = useOverlayScrollbars({
    options: {
      scrollbars: {
        autoHide: "never",
        visibility: "auto",
        pointers: ["mouse", "touch", "pen"],
      },
    },
    events: {
      initialized: () => {},
      updated: () => {
        setScrollReady(true);
      },
    },
    defer: true,
  });

  const setScrollPostion = () => {
    const defaultPos = 1450;

    if (scrollReady) {
      const current = new Date();
      let scroll = 0;
      //   setScrollTo(scroll);

      let startTime = (getHours(current) - 1) * 200; //200 is the width of hourly block in pixel

      if (
        isAfter(
          toDate(format(currentDate, "MM/dd/yyyy")),
          toDate(format(current, "MM/dd/yyyy"))
        ) ||
        isBefore(
          toDate(format(currentDate, "MM/dd/yyyy")),
          toDate(format(current, "MM/dd/yyyy"))
        )
      ) {
        scroll = defaultPos; //default to morning time if it is on a different date
      } else {
        scroll = startTime;
      }

      if (scroll !== scrollTo || scroll === defaultPos) {
        setScrollTo(scroll);
      }
    }
  };
  useEffect(() => {
    setScrollPostion();
  }, [currentDate, scrollReady]);

  //scroll left to th4e correct position on the scheduler based on time of the day and currentdate
  useEffect(() => {
    const el = instance();

    if (el) {
      const { viewport } = el.elements();

      viewport.scroll({ left: scrollTo });
    }
  }, [scrollTo, currentDate]);

  useEffect(() => {
    initialize(ref.current!);
  }, [initialize]);

  const [resources, setResources] = useState([...resList]);
  const [appointmentList, setAppointmentList] = useState([...appts]);
  return (
    <div ref={ref} className="w-full h-full h-full">
      <ResourcePanel resources={resources} />

      <SchedulerPanel
        toggleEditor={toggleEditor}
        onCurrentDateChange={onCurrentDateChange}
        setScrollPostion={setScrollPostion}
        resources={resources}
        currentDate={currentDate}
        // setCurrentDate={setCurrentDate}
        appointmentList={appointmentList}
        setAppointmentList={setAppointmentList}
        setScrollTo={setScrollTo}
        scrollTo={scrollTo}
      />
      {editor && editor == "1" && (
        <ScheduleEditor toggleEditor={toggleEditor} />
      )}
    </div>
  );
}
// export default Scheduler;
