import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <div id="error-page">
      <h1>ERROR</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {/* {isRouteErrorResponse(error) && (
          <i>{error.statusText || error.status}</i>
        )} */}
      </p>
    </div>
  );
}
