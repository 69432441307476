import Xarrow from "react-xarrows";
import interact from "interactjs";
// import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import ScheduleBlock from "./scheduleBlock";
import ScheduleHeader from "./scheduleHeader";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
// import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import PrintIcon from "@mui/icons-material/Print";
// import ShareIcon from "@mui/icons-material/Share";
import Search from "./Search";
import { format, toDate } from "date-fns";

const SchedulerPanel = (props: any) => {
  const {
    onCurrentDateChange,
    resources,
    currentDate,
    setScrollPostion,
    appointmentList,
    scrollTo,
    setScrollTo,
    toggleEditor,
  } = props;
  // const [popoverAnchorEl, setPopoverAnchorEl] =
  //   React.useState<HTMLButtonElement | null>(null);
  // const popOverOpen = Boolean(popoverAnchorEl);
  // const id = popOverOpen ? "info-popover" : undefined;

  const addRefBlock = useRef<any>(null);
  // const [newAppointment, setNewAppointment] = useState<any>(null);
  const [timerDate, setTimerDate] = useState<any>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState(0);
  const [contextMenuTopPos, setContextMenuTopPos] = useState(0);
  const [connectors, setConnectors] = useState<any>([]);
  const [blocks, setBlocks] = useState<any>({});
  const [currentService, setCurrentService] = useState<any>(null);
  const [currentAppointment, setCurrentAppointment] = useState<any>(null);
  const [showEditAppointment, setShowEditAppointment] = useState(false);
  const [timeBlock, setTimeBLock] = useState(0);
  const [resourceList, setResourceList] = useState<any>(
    JSON.parse(JSON.stringify(resources))
  );
  const [showSearch, setShowSearch] = useState(false);
  // const [openSplitServiceDialog, setOpenSplitServiceDialog] =
  //   React.useState(false);
  // const [openAddServiceDialog, setOpenAddServiceDialog] = React.useState(false);
  // const [openDeleteServiceDialog, setOpenDeleteServiceDialog] =
  //   React.useState(false);

  // const handleClickOpenSplitServiceDialog = () => {
  //   setOpenSplitServiceDialog(true);
  // };
  // const handleClickAddSplitServiceDialog = () => {
  //   setOpenAddServiceDialog(true);
  // };
  // const handleClickDeleteSplitServiceDialog = () => {
  //   setOpenDeleteServiceDialog(true);
  // };
  // const handleCloseSplitServiceDialog = () => {
  //   setOpenSplitServiceDialog(false);
  // };
  // const handleCloseAddServiceDialog = () => {
  //   setOpenAddServiceDialog(false);
  // };

  // const handleCloseDeleteServiceDialog = () => {
  //   setOpenDeleteServiceDialog(false);
  // };

  const [currentTimePosition, setCurrentTimePosition] = useState(0);

  const position = { x: 0, y: 0 };

  const isCurrentDateToday = () => {
    if (
      format(currentDate, "MM/dd/yyyy") === format(new Date(), "MM/dd/yyyy")
    ) {
      return true;
    } else return false;
  };

  const resetResources = () => {
    setResourceList(JSON.parse(JSON.stringify(resources)));
  };

  const getTodayAppointment = () => {
    let appt = appointmentList.filter(
      (x: any) => x.appointment_date === format(currentDate, "MM/dd/yyyy") //currentDate.format("YYYY-MM-DD")
    );

    return appt;
  };
  const [appointments, setAppointments] = useState<any>([]);

  //adding appointment blocks on calendar
  useEffect(() => {
    let zIndex = 10;
    let appList = [...appointments];
    appList.forEach((a) => {
      let values: any[] = [];
      let conn = { id: a.id, connectors: values };

      if (
        format(toDate(a.appointment_date), "MM/dd/yyyy") ===
        format(currentDate, "MM/dd/yyyy")
      ) {
        a.services
          .sort(function (a: any, b: any) {
            if (a.start_hour === b.start_hour) {
              if (a.start_min > b.start_min) return 1;
              if (a.start_min < b.start_min) return -1;
            } else {
              if (a.start_hour > b.start_hour) return 1;
              if (a.start_hour < b.start_hour) return -1;
            }

            return 0;
          })
          .forEach((s: any, sIdx: any) => {
            const hIdx = parseInt(s.start_hour.toString());
            const mIdx = parseInt(s.start_min.toString()) / 15;
            const duration = parseInt(s.duration.toString()) / 15;

            resourceList.forEach((res: any, resIdx: any) => {
              if (res.id === s.resource_id) {
                let maxCount = 1;
                const id = `${resIdx}_${hIdx}_${mIdx}`;

                //set border color if it is overlapping
                let borderColor = "#03E562";
                let exists = a.services.find(
                  (f: any) =>
                    f.start_hour === s.start_hour &&
                    f.start_min === s.start_min &&
                    f.id !== s.id &&
                    f.resource_id === s.resource_id
                );

                if (exists) {
                  borderColor = "#02A948";
                }
                if (!blocks[id]) {
                  blocks[id] = [];
                }

                blocks[id].push({
                  id: `${id}_${s.id}`,
                  name: s.service_name,
                  position: "absolute",
                  width: 50 * duration,
                  height: `${36 / maxCount}px`,
                  backgroundColor: "rgba(204, 243, 129, .6)",
                  className: "resizable",
                  border: "solid 1px " + borderColor,
                  resourceIdx: resIdx,
                  hourIdx: hIdx,
                  minuteIdx: mIdx,
                  startHour: s.start_hour,
                  startMinute: s.start_min,
                  duration: s.duration,
                  appointmentId: a.id,
                  serviceId: s.id,
                  resource_id: res.id,
                  zIndex: zIndex,
                });
                zIndex++;
                setBlocks(JSON.parse(JSON.stringify(blocks)));

                conn.connectors.push(`${id}_${s.id}`);
              }
            });
          });

        let conns = [...connectors];
        conns.push(conn);
        setConnectors([...conns]);
      }
    }); //end apptList foreach
  }, [appointments]);

  useEffect(() => {
    resetResources();
    setBlocks({});
    setConnectors([]);
    let p = getTodayAppointment();

    setAppointments(getTodayAppointment());
    setCurrentAppointment(null);
    setCurrentService(null);
    setShowContextMenu(false);
    setShowEditAppointment(false);
    //publish current date change event
  }, [currentDate]);
  //init drag/drop/resize
  useEffect(() => {
    interact(".resizable").resizable({
      edges: {
        top: false,
        left: false,
        bottom: false,
        right: true,
      },
      listeners: {
        move: function (event) {
          // setShowContextMenu(false);
          //   setShowEditAppointment(false);
          let { x, y } = event.target.dataset;

          x = (parseFloat(x) || 0) + event.deltaRect.left;
          y = (parseFloat(y) || 0) + event.deltaRect.top;

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`,
          });

          Object.assign(event.target.dataset, { x, y });
          setConnectors([...connectors]);
        },
        end: function (event) {
          let width = event.target.clientWidth;
          let duration = Math.ceil(parseInt(width) / 50);
          //find the appointment and modify the "duration"
          const id = event.target.id.split("_");
          if (id.length === 4) {
            const blockId = `${id[0]}_${id[1]}_${id[2]}`;
            const svcId = parseInt(id[3]);
            let block = blocks[blockId];
            if (block) {
              let service = block.find((b: any) => b.serviceId === svcId);
              if (service) {
                let appt = appointments.find(
                  (x: any) => x.id === service.appointmentId
                );

                if (appt) {
                  // console.log(appt.services);
                  let svc = appt.services.find(
                    (y: any) => y.id === service.serviceId
                  );

                  if (svc) {
                    svc.duration = duration * 15;

                    setAppointments([...appointments]);
                    setBlocks({});
                  }
                }
              }
            }
          }
        },
      },
    });

    interact(".draggable").draggable({
      autoScroll: true,
      listeners: {
        start(event) {
          // console.log(event.type, event.target);
          setShowContextMenu(false);
          //   setShowEditAppointment(false);
        },
        end(event) {
          position.x = 0;
          position.y = 0;
        },
        move(event) {
          position.x += event.dx;
          position.y += event.dy;
          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;

          setConnectors([...connectors]);
        },
      },
    });

    interact(".droppable").dropzone({
      accept: ".draggable",
      ondragenter: function (event) {
        event.target.style.backgroundColor = "#08C15A";
      },
      ondragleave: function (event) {
        event.target.style.backgroundColor = "#fff";
      },
      ondrop: function (event) {
        event.target.style.backgroundColor = "#fff";
        // console.log(appointments);

        const current = event.relatedTarget.id;
        const currentArr = current.split("_");

        const target = event.currentTarget.id;
        //find the new timeslot, update appointments with the new resource && timeslot
        let block =
          blocks[`${currentArr[0]}_${currentArr[1]}_${currentArr[2]}`];
        if (block) {
          let service = block.find((b: any) => b.id === current);

          if (service) {
            let appt = appointments.find(
              (x: any) => x.id === service.appointmentId
            );

            if (appt) {
              // console.log(appt.services);
              let svc = appt.services.find(
                (y: any) => y.id === service.serviceId
              );

              if (svc) {
                let slot = target.split("_");
                if (slot && slot.length === 4) {
                  const resource = parseInt(slot[1]);
                  let slotHour = slot[2];
                  let slotMinute = parseInt(slot[3]) * 15;
                  svc.start_hour = parseInt(slotHour);
                  svc.start_min = slotMinute;
                  svc.resource_id = resourceList[resource].id;

                  setAppointments([...appointments]);
                  setBlocks({});
                }
              }
            }
          }
        }
      },
    });
  }, [blocks]);

  useEffect(() => {
    interact(".click-draggable").draggable({
      startAxis: "x",
      lockAxis: "x",
      listeners: {
        start(event) {
          let top = event.target.offsetTop;
          let left = event.target.offsetLeft;
          addRefBlock.current.style.width = "10px";
          addRefBlock.current.style.top = `${top}px`;
          addRefBlock.current.style.left = `${left}px`;
          addRefBlock.current.style.visibility = "visible";
          // setNewAppointment({ resource_id: 1});
          // newAppointment.resource_id= resource_id ;
        },
        end(event) {
          let resourceIdx = event.target.attributes["data-resource"].value;
          const resource_id = resources[resourceIdx].id;
          const hour = event.target.attributes["data-hour"].value;
          const minute = event.target.attributes["data-minute"].value;

          let duration = Math.floor(position.x / 50);
          let r = position.x % 50;
          if (r > 25) {
            duration = duration + 1;
          }
          setTimeBLock(duration);
          // setNewAppointment({
          //   resource_id: resource_id,
          //   hour: hour,
          //   minute: minute,
          //   duration: duration * 15,
          // });
          const appt = {
            id: undefined,
            appointment_date: format(currentDate, "MM/dd/yyyy"), //moment(currentDate).format("YYYY-MM-DD"),
            services: [
              {
                id: undefined,
                resource_id: resource_id,
                duration: duration * 15,
                start_hour: hour,
                start_min: minute,
              },
            ],
          };
          setCurrentAppointment(appt);

          // setShowEditAppointment(true);
          toggleEditor();
          position.x = 0;
          position.y = 0;
          addRefBlock.current.style.width = "0px";
          addRefBlock.current.style.top = "0px";
          addRefBlock.current.style.left = "0px";
        },
        move(event) {
          if (addRefBlock.current) {
            position.x = position.x + event.delta.x;
            addRefBlock.current.style.width = position.x + "px";
            let duration = Math.floor(position.x / 50);
            let r = position.x % 50;
            if (r > 25) {
              duration = duration + 1;
            }
            setTimeBLock(duration);
          }
        },
      },
    });
  }, []);
  function refreshClock() {
    setTimerDate(new Date());
    //calculate where to put the line
    //each minute block is 50px, hour is 200px
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000 * 60);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    let t = timerDate || new Date();

    let h = t.getHours();
    let m = t.getMinutes();
    let pos = h * 200 + (200 / 60) * m;
    setCurrentTimePosition(pos);
    setScrollPostion(); //scroll right based on current time to always keep current time in visibility
  }, [timerDate]);

  // const actions = [
  //   { icon: <FileCopyIcon />, name: "Copy" },
  //   { icon: <SaveIcon />, name: "Save" },
  //   { icon: <PrintIcon />, name: "Print" },
  //   { icon: <ShareIcon />, name: "Share" },
  // ];

  return (
    <div className="scheduler-panel" style={{ paddingBottom: "20px" }}>
      <div className="fixed text-white  pr-1  right-0 z-20 flex flex-row">
        <button
          className="btn btn-link text-white"
          onClick={() => {
            //scroll to current time (+1 so it detects the change and react to it, -1 so it gets back the the position)
            setScrollTo(scrollTo + 1);
            setScrollTo(scrollTo - 1);
            // setScrollPostion();
          }}
        >
          {timerDate
            ? timerDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
        </button>
        <button
          className="btn btn-link text-white"
          onClick={() => {
            setShowSearch(true);
          }}
        >
          <SearchIcon fontSize="medium" />
        </button>
      </div>
      <ScheduleHeader
        toggleEditor={toggleEditor}
        currentDate={currentDate}
        onCurrentDateChange={onCurrentDateChange}
      />

      <div className="scheduler-body">
        <Search showSearch={showSearch} setShowSearch={setShowSearch} />

        {showContextMenu && (
          // <ClickAwayListener
          //   onClickAway={() => {
          //     setShowContextMenu(false);
          //     setCurrentService(null);
          //     setCurrentAppointment(null);
          //   }}
          // >    </ClickAwayListener>
          <div
            className="  absolute context px-2 border-solid border border-gray-300 shadow-xl text-white bg-green-700 rounded-md flex flex-row justify-between items-center"
            style={{
              width: "200px",
              height: "35px",
              left: `${contextMenuPosition}px`,
              top: `${contextMenuTopPos}px`,
            }}
          >
            <div>
              <button
                className="btn btn-link"
                onClick={(event: any) => {
                  //setPopoverAnchorEl(event.currentTarget);
                }}
              >
                <InfoSharpIcon fontSize="small" />
              </button>
            </div>
            <div>
              {/*                
                  <IconButton
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setShowContextMenu(false);
                      setOpenSplitServiceDialog(!openSplitServiceDialog);
                    }}
                  >
                    <AccountTreeSharpIcon fontSize="small" />
                  </IconButton> */}
            </div>
            <div>
              {/* <Tooltip title="Add Another Service">
                  <IconButton
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setShowContextMenu(false);
                      setOpenAddServiceDialog(!openAddServiceDialog);
                    }}
                  >
                    <AddToPhotosSharpIcon fontSize="small" />
                  </IconButton>
                </Tooltip> */}
            </div>
            <div>
              {/* <Tooltip title="Edit Appointment">
                  <IconButton
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setShowContextMenu(false);
                      setShowEditAppointment(true);
                    }}
                  >
                    <EditCalendarIcon fontSize="small" />
                  </IconButton>
                </Tooltip> */}
            </div>
            <div>
              {/* <Tooltip title="Cancel Appointment or Service">
                  <IconButton
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setShowContextMenu(false);
                      setOpenDeleteServiceDialog(true);
                    }}
                  >
                    <EventBusyIcon fontSize="small" />
                  </IconButton>
                </Tooltip> */}
            </div>
          </div>
        )}

        {/* <AddUpdateAppointment
          showEditAppointment={showEditAppointment}
          setCurrentAppointment={setCurrentAppointment}
          setCurrentService={setCurrentService}
          setShowEditAppointment={setShowEditAppointment}
        /> */}
        {resourceList.map((x: any, idx: any) => {
          // if (!blockRef.current[idx]) {
          //   blockRef.current[idx] = [[]];
          // }
          return (
            <div
              key={x.id}
              className="row border-0 border-solid border-b border-gray-200 border-b-gray-300"
            >
              {[...Array(24)].map((w, i) => (
                <div
                  className="hour  border-0 border-solid border-r border-gray-300"
                  key={i}
                >
                  {[...Array(4)].map((y, z) => {
                    return (
                      <div
                        id={`p_${idx}_${i}_${z}`}
                        key={z}
                        data-resource={idx}
                        data-hour={i}
                        data-minute={z}
                        data-count="0"
                        className={`minute droppable  border-0 border-solid border-r   border-gray-200 click-draggable ${
                          i < 8 || i > 20 ? "bg-gray-100" : "bg-white"
                        }`}
                      >
                        {blocks.hasOwnProperty(`${idx}_${i}_${z}`) &&
                          blocks[`${idx}_${i}_${z}`].map((bl: any) => {
                            return (
                              <ScheduleBlock block={bl} key={bl.id}>
                                <div
                                  className={`flex flex-row justify-start w-full relative `}
                                >
                                  <div
                                    className={`pr-7  flex flex-row`}
                                    style={{ minWidth: "20px", width: "20px" }}
                                  >
                                    <button
                                      className="btn btn-link"
                                      onClick={() => {
                                        let leftPos = i * 200 + z * 50;
                                        let topPos = idx * 50 + 45;
                                        let appt = appointments.find(
                                          (a: any) => a.id === bl.appointmentId
                                        );
                                        if (appt) {
                                          setCurrentAppointment(appt);
                                          let s = appt.services.find(
                                            (s: any) => s.id === bl.serviceId
                                          );

                                          setCurrentService(s);
                                        }
                                        setShowEditAppointment(false);
                                        setCurrentAppointment(appt);
                                        setShowContextMenu(true);
                                        setContextMenuPosition(leftPos);
                                        setContextMenuTopPos(topPos);
                                      }}
                                    >
                                      <KeyboardDoubleArrowDownIcon fontSize="small" />
                                    </button>
                                  </div>
                                  <div
                                    className={`text-ellipsis flex items-center  ${
                                      currentService &&
                                      bl.serviceId === currentService.id
                                        ? "text-red-600 font-bold"
                                        : "text-black"
                                    }`}
                                  >
                                    <button
                                      onClick={() => {
                                        let appt = appointments.find(
                                          (a: any) => a.id === bl.appointmentId
                                        );
                                        setCurrentAppointment(appt);
                                        setShowEditAppointment(
                                          !showEditAppointment
                                        );
                                      }}
                                    >
                                      {bl.name}
                                    </button>
                                    {bl.name}
                                  </div>
                                </div>
                              </ScheduleBlock>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          );
        })}

        {/* invisible box used for drag to add event to scheduler */}
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "0px",
            height: "40px",
            maxHeight: "45px",
            visibility: "hidden",
            zIndex: 200,
          }}
          className="bg-gray-100 mx-1 opacity-80 rounded-md shadow-md  border-0 border-solid border-2 border-gray-400 mt-1 text-black font-bold flex flex-row pl-1 items-center overflow-clip whitespace-nowrap text-sm"
          ref={addRefBlock}
        >
          NEW: {timeBlock * 15} minute
        </div>
      </div>

      {isCurrentDateToday() && (
        <div
          style={{
            height: "100%",
            width: "0px",
            position: "absolute",
            top: "0px",
            left: `${currentTimePosition}px`,
          }}
          className=" border-0 border-solid border-r-2 opacity-50   shadow-sm border-r-orange-700"
        ></div>
      )}
      {/* render the connectors */}
      {connectors.map((x: any, k: any) => {
        // console.log(x);
        let start: any = null;
        return (
          <div key={k}>
            {x.connectors.map((y: any, z: any) => {
              if (start === null) {
                start = y;
                return <span key={z}></span>;
              } else {
                return (
                  <Xarrow
                    key={z}
                    start={start}
                    end={y}
                    startAnchor="left"
                    gridBreak="3%"
                    strokeWidth={1}
                    headSize={8}
                    path="smooth"
                  />
                );
              }
            })}
          </div>
        );
      })}
      {/* dialog for  splitting selected service to multiple resources */}
      {/* <SplitService
        openSplitServiceDialog={openSplitServiceDialog}
        resources={resources}
        currentService={currentService}
        handleCloseSplitServiceDialog={handleCloseSplitServiceDialog}
      /> */}
      {/* dialog for adding another service to same appointment */}
      {/* <AddService
        openAddServiceDialog={openAddServiceDialog}
        resources={resources}
        currentAppointment={currentAppointment}
        handleCloseAddServiceDialog={handleCloseAddServiceDialog}
      /> */}
      {/* dialog for delete service confirmation */}
      {/* <DeleteService
        openDeleteServiceDialog={openDeleteServiceDialog}
        handleCloseDeleteServiceDialog={handleCloseDeleteServiceDialog}
      /> */}

      {/* <Box
        sx={{
          height: 320,
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "fixed",
          right: "10px",
          top: "140px",
        }}
      >
        <SpeedDial
          direction="down"
          ariaLabel="SpeedDial basic example"
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 501,
          }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
      </Box> */}
    </div>
  );
};

export default SchedulerPanel;
