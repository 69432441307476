import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage.tsx";
import Home from "./components/Home.tsx";
import OrganizationHome from "./components/organization/OrganizationHome.tsx";
import OrganizationDashboard from "./components/organization/OrganizationDashboard.tsx";
import { create } from "zustand";
import { User } from "./types/User.ts";
// import { useLoadUserData } from "./api/user";
// import { useEffect } from "react";
import Scheduler from "./components/scheduler/Scheduler.tsx";
import DayView from "./components/organization/DayView.tsx";
import OrganizationMain from "./components/organization/Main.tsx";
import OrganizationSetting from "./components/organization/OrganizationSetting.tsx";
import AppBuilder from "./components/builder/Builder.tsx";
import Org from "./components/organization/setting/Org.tsx";
import Resource from "./components/organization/setting/Resource.tsx";
import Client from "./components/organization/setting/Client.tsx";
import Service from "./components/organization/setting/Service.tsx";
import OrganizationServices from "./components/organization/OrganizationServices.tsx";
import OrganizationResources from "./components/organization/OrganizationResource.tsx";
// import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/prism",
        element: <OrganizationHome />,
        errorElement: <ErrorPage />,
        children: [
          { path: "", element: <Navigate to="main" replace={true} /> },
          {
            path: "main",
            element: <OrganizationMain />,
          },
          {
            path: ":orgId",
            element: <OrganizationDashboard />,
            children: [
              { path: "", element: <Navigate to="day" replace={true} /> },
              { path: "scheduler", element: <Scheduler /> },
              { path: "day", element: <DayView /> },
              { path: "services", element: <OrganizationServices /> },
              { path: "resources", element: <OrganizationResources /> },
              {
                path: "setting",
                element: <OrganizationSetting />,
                children: [
                  { path: "org", element: <Org /> },
                  { path: "resource", element: <Resource /> },
                  { path: "client", element: <Client /> },
                  { path: "service", element: <Service /> },
                ],
              },

              { path: "builder", element: <AppBuilder /> },
            ],
          },
        ],
      },
      {
        path: "",
        element: <Navigate to="prism" replace={true} />,
      },
    ],
  },
]);

export type GlobalState = {
  user: User;
  loading: boolean;
  error: boolean;
  errorMessage: string;
};

export const useGlobalStore = create((set) => ({
  user: {},
  loading: false,
  error: false,
  errorMessage: "",
  setErrorMessage: (error: string) => {
    set((state: GlobalState) => ({ ...state, errorMessage: error }));
  },
  setErrorState: (error: boolean) => {
    set((state: GlobalState) => ({ ...state, error: error }));
  },
  setLoading: (loading: boolean) => {
    set((state: GlobalState) => ({ ...state, loading: loading }));
  },
  updateUser: (usr: any) =>
    set((state: GlobalState) => ({ ...state, user: usr })),
  clearUser: () => set({ user: null }),
}));
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
