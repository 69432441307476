import Close from "../../icon/Close";
import DatePicker from "react-datepicker";
import DatePickerInput from "./DatePickerInput";
import { useState } from "react";
import Schedule from "../../types/Schedule";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { format } from "date-fns";
// import Calendar from "../icons/Calendar";
const sectionHeaderStyle = " pb-2  text-md font-bold pt-6";
const sectionStyle =
  "flex flex-row w-full bg-gray-100 p-4 shadow-xl text-sm rounded-md";
export default function ScheduleEditor(props: any) {
  const { toggleEditor } = props;
  const toHours = (mins: any) => {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    // h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h} hr ${m} min`;
  };

  //   const { mutate } = useMutateOrganizationData(user);
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Schedule>({
    defaultValues: props.org
      ? props.org
      : {
          start_date: new Date(),
        },
  });

  //   const [org, setOrg] = useState(
  //     props.org ? JSON.parse(JSON.stringify(props.org)) : {}
  //   );

  const onSubmit: SubmitHandler<Schedule> = (data) => {
    console.log(data);
    // mutate(data);
  };

  return (
    <div className="fixed left-0 top-0 w-full h-full p-4  bg-black bg-opacity-75 z-20 flex justify-end">
      <div className="bg-white w-1/2 h-full rounded-sm rounded flex flex-col">
        <div className="flex justify-between items-center p-2 border border-t-0 border-b-1 border-r-0 border-l-0 bg-gray-600 text-white shadow-sm">
          <span className="text-lg bold">BOOKING EDITOR</span>
          <span>
            <button
              onClick={() => {
                toggleEditor();
              }}
              className="btn btn-link text-white"
            >
              <Close />
            </button>
          </span>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form-controls w-full"
        >
          <div className="flex flex-col px-4">
            <div className={sectionHeaderStyle}>1. BOOKING DETAILS</div>
            <div className={sectionStyle}>
              <div className="flex flex-col pt-4 w-1/2 ml-2">
                <span>DATE</span>

                <DatePicker
                  value={format(getValues().start_date, "MM/dd/yyyy")}
                  onChange={(e): any => {
                    if (e) {
                      setValue("start_date", e, { shouldValidate: true });
                    }
                  }}
                  customInput={
                    <DatePickerInput
                      className="hover:bg-white bg-white
                    hover:text-black
                    hover:border-gray-300 border-gray-300 text-gray-600 regular"
                    />
                  }
                />
              </div>

              <div className="flex flex-col pt-4 ml-2">
                <span>TIME</span>
                <div className="relative">
                  <select
                    className="select select-bordered select-md"
                    {...register("hour")}
                  >
                    {[...Array(12)].map((x, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col pt-4 ml-1">
                <span>&nbsp;</span>
                <div className="relative">
                  <select
                    className="select select-bordered select-md"
                    {...register("minute")}
                  >
                    {[...Array(4)].map((x, i) => (
                      <option key={i} value={i * 15}>
                        {i * 15 == 0 ? "00" : i * 15}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col pt-4 ml-1">
                <span>&nbsp;</span>
                <div className="relative">
                  <select
                    className="select select-bordered select-md"
                    {...register("am_pm")}
                  >
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col pt-4 w-1/2 ml-2">
                <span>HOW LONG?</span>

                <select
                  className="select select-bordered select-md"
                  {...register("duration")}
                >
                  {[...Array(32)].map((x, i) => (
                    <option key={i} value={i * 15 + 15}>
                      {toHours(i * 15 + 15)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={sectionHeaderStyle}>2. CLIENT</div>
            <div className={sectionStyle}>
              <div className="flex flex-col w-full">
                <span>CUSTOMER PHONE OR EMAIL</span>
                <div className="flex flex-col w-full items-end">
                  <input
                    type="text"
                    className="input input-md input-bordered w-full grow"
                  />
                  <button className="btn btn-default btn-md mt-1 shadow-lg w-20">
                    NEXT
                  </button>
                </div>
              </div>
            </div>
            <div className={sectionHeaderStyle}>3. SERVICES</div>
            <div className={sectionStyle}>
              <div className="flex flex-col w-full">
                <span>CUSTOMER PHONE OR EMAIL</span>

                <input
                  type="text"
                  className="input input-md input-bordered w-96 grow mr-2"
                />
                <button className="btn btn-primary btn-md">NEXT</button>
              </div>
            </div>
          </div>
          <div className="p-4 flex justify-end">
            <button type="submit" className="btn btn-primary btn-md px-6">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
