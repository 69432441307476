import { Outlet } from "react-router-dom";
import { useGlobalStore } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
// import { useEffect } from "react";
import { withAuth0 } from "@auth0/auth0-react";
// import { useLoadUserData, useMutateUser } from "../api/user";
import { useEffect, useState } from "react";
// import { User } from "../types/User";

const Loading = () => {
  return (
    <div className="fixed w-screen h-screen bg-black text-white opacity-50 flex justify-center items-center flex-col">
      <span className="loading loading-dots loading-lg"></span>
      <span>Loading...</span>
    </div>
  );
};

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1000);
  }, []);

  return (
    <div className="flex w-screen h-screen bg-black fixed left-0 top-0 z-40 justify-center items-center opacity-75">
      <div className="flex flex-col bg-white opacity-100 p-4 px-8 rounded rounded-sm justify-center items-center">
        <span>AUTHENTICATING! PLEASE WAIT...</span>
        <div className="flex flex-row pt-4">
          {showButton && (
            <button
              onClick={() => {
                loginWithRedirect();
              }}
              className="btn btn-md btn-link"
            >
              CONTINUE LOGIN...
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
function Home() {
  const loading = useGlobalStore((state: any) => state.loading);
  const { isAuthenticated } = useAuth0();

  // const [token, setToken] = useState("");

  // getAccessTokenSilently().then((token) => {
  //   setToken(token);
  // });
  // const { mutate } = useMutateUser();

  // // console.log(user);
  // const { data, isError, isFetching } = useLoadUserData(
  //   user && user.email ? user.email : ""
  // );

  // const setLoading = useGlobalStore((state: any) => state.setLoading);
  // const updateUser = useGlobalStore((state: any) => state.updateUser);
  // const setErrorState = useGlobalStore((state: any) => state.setErrorState);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //   }
  // }, [isAuthenticated]);
  // useEffect(() => {
  //   setLoading(isFetching);
  //   setErrorState(isError);

  //   if (data) {
  //     updateUser(data);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (user && isAuthenticated) {
  //     let _newUser: User = {
  //       first_name: user?.given_name,
  //       last_name: user?.family_name,
  //       email_address: user?.email,
  //       email_verified: user?.email_verified,
  //       picture: user?.picture,
  //       sub: user?.sub,
  //       nickname: user?.nickname,
  //     };
  //     mutate(_newUser);
  //   }
  // }, [user, isAuthenticated]);

  // //if user exists, but not in the database, then create a new profile for the user
  // if (!data && user) {
  //   //TODO: update user profile since this is the first time user logged in
  //   let _newUser: User = {
  //     first_name: user?.given_name,
  //     last_name: user?.family_name,
  //     email_address: user?.email,
  //     email_verified: user?.email_verified,
  //     picture: user?.picture,
  //     sub: user?.sub,
  //     nickname: user?.nickname,
  //   };

  //   mutate(_newUser);
  //   console.log(_newUser);
  //   //add user

  //   //then reload
  // }
  return (
    <>
      {isAuthenticated && (
        <div className="w-full h-screen bg-gray-400 flex">
          {loading && <Loading />}

          <div
            className={`grow flex flex-col  text-black bg-white overflow-hidden`}
          >
            <Outlet />
          </div>
        </div>
      )}
      {!isAuthenticated && <Login />}
    </>
  );
}

export default withAuth0(Home);
