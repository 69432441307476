import { Link, Outlet, useParams, useSearchParams } from "react-router-dom";
import { useOrganizationData } from "../../api/organization";
import OrgIcon from "../../icon/Org";
import UserGroupIcon from "../../icon/UserGroupIcon";
import UsersIcon from "../../icon/UsersIcon";
import TagIcon from "../../icon/TagIcon";
import Org from "./setting/Org";
import Resource from "./setting/Resource";
import ServiceSetting from "./setting/Service";
import Client from "./setting/Client";
import ServiceEditor from "./setting/ServiceEditor";
import { useLoadOrganizationServiceById } from "../../api/service";

export default function OrganizationSetting() {
  let { orgId } = useParams();
  const { data } = useOrganizationData(orgId);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const cmp = searchParams.get("cmp");
  const action = searchParams.get("action");
  //   console.log(cmp);

  const sId = searchParams.get("sId");

  let { data: service } = useLoadOrganizationServiceById(sId ? sId : "");

  return (
    <div className="w-full h-full flex flex-row  bg-base_100">
      <div className="w-[400px] pt-6 h-screen overflow-y-auto ">
        <div className="w-full pt-[175px] relative">
          <div className="flex flex-col px-4 justify-end text-lg bold  uppercase fixed  bg-base-100 border border-x-0 border-t-0 border-b-1 border-b-gray-200 h-[200px]  top-0 w-[400px]">
            <div className="flex justify-center text-gray-400">
              {data?.name}
            </div>
            <div className="pt-4 w-full flex justify-center">
              <ul className="menu menu-horizontal bg-base_100 rounded-box">
                {/* setting */}
                <li>
                  <button
                    onClick={() => {
                      setSearchParams({ ...params, cmp: "org" });
                    }}
                    className="tooltip btn btn-link"
                    data-tip="Organization"
                  >
                    <OrgIcon />
                  </button>
                </li>

                <li>
                  {/* users & resources */}
                  <button
                    onClick={() => {
                      setSearchParams({ ...params, cmp: "resource" });
                    }}
                    className="tooltip btn btn-link"
                    data-tip="Resources"
                  >
                    <UserGroupIcon />
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      setSearchParams({ ...params, cmp: "client" });
                    }}
                    className="tooltip btn btn-link"
                    data-tip="Clients"
                  >
                    <UsersIcon />
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      setSearchParams({ ...params, cmp: "service" });
                    }}
                    className="tooltip btn btn-link"
                    data-tip="Services"
                  >
                    <TagIcon />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-100">
            {(!cmp || cmp === "org") && <Org />}
            {cmp && cmp === "resource" && <Resource />}
            {cmp && cmp === "service" && <ServiceSetting />}
            {cmp && cmp === "client" && <Client />}
          </div>
        </div>
      </div>
      <div className="flex grow shadow-2xl z-10">
        <div className="bg-white w-full h-full">
          {cmp && cmp == "service" && action && action == "add" && (
            <ServiceEditor />
          )}
          {cmp && cmp == "service" && action && action == "update" && (
            <ServiceEditor service={service} />
          )}
        </div>
      </div>
    </div>
  );
}
