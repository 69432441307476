import { Outlet, useParams } from "react-router-dom";
import { useOrganizationData } from "../../api/organization";

export default function OrganizationDashboard() {
  let { orgId } = useParams();
  const orgData = useOrganizationData(orgId);
  if (orgData) {
  }

  return (
    <>
      <div>
        {/* <div>{orgData.data?.name}</div> */}
        <div className="w-full h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
}
