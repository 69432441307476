// import moment from "moment";
import { format, toDate, add, sub } from "date-fns";
import { convert } from "./utils";
// import React, { useEffect } from "react";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowCircleLeftSharpIcon from "@mui/icons-material/ArrowCircleLeftSharp";
import ArrowCircleRightSharpIcon from "@mui/icons-material/ArrowCircleRightSharp";
// import AirDatepicker from "air-datepicker";
// import localeEn from "air-datepicker/locale/en";
// import { useEffect, useRef } from "react";
// import { green } from "@mui/material/colors";
import DatePicker from "react-datepicker";
import DatePickerInput from "./DatePickerInput";
// import { useEffect } from "react";
// import ReactDOM from "react-dom";
const ScheduleHeader = (props: any) => {
  const { onCurrentDateChange } = props;
  const { toggleEditor } = props;

  const currentDate = toDate(props.currentDate);

  // const dpRef = useRef(null);

  // useEffect(() => {
  //   if (dpRef.current) {
  //     new AirDatepicker(dpRef.current, {
  //       autoClose: true,
  //       buttons: ["today", "clear"],
  //       dateFormat: "yyyy-MM-d",
  //       locale: localeEn,
  //       inline: true,
  //       onSelect: ({ date, formattedDate, datepicker }) => {
  //         const dt = moment(formattedDate);

  //         // setCurrentDate(dt);
  //       },
  //     });
  //   }
  // }, [dpRef]);
  // const dp = document.querySelector(".react-datepicker__input-container");
  // let dpinput = dp?.querySelector("input");
  // let classes = dpinput?.classList;
  // console.log(classes?.value);
  // useEffect(() => {
  //   if (dpinput) {
  //     dpinput.classList.add("input");
  //     dpinput.classList.add("input-sm");
  //     dpinput.classList.add("input-bordered");
  //   }
  // }, [classes?.value]);
  return (
    <div className="scheduler-panel-header z-10 bg-gray-200 border-0 border-solid border-b-2 border-b-gray-600">
      <div>
        <div className="border-0 border-solid border-b-2 border-b-gray-600 relative bg-gray-600 text-white">
          <div
            className="z-10 flex justify-start items-center"
            style={{
              paddingLeft: "5px",
              position: "sticky",
              left: "200px",
              width: "800px",
            }}
          >
            <span>
              <button
                className="text-white btn btn-link"
                onClick={() => {
                  let dt = sub(currentDate, { days: 1 });
                  onCurrentDateChange(dt);
                }}
              >
                <ArrowCircleLeftSharpIcon fontSize="small" />
              </button>

              {/* <button
                className="btn  btn-primary m-1  btn-active btn-sm "
                onClick={() => {
                  let dt = moment(currentDate.format("YYYY-MM-DD")).add(
                    -1,
                    "d"
                  );
                  setCurrentDate(dt);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
            </span>
            <span>
              <button
                onClick={() => {
                  if (
                    format(currentDate, "MM/dd/yyyy") !==
                    format(toDate(new Date()), "MM/dd/yyyy")
                  ) {
                    onCurrentDateChange(toDate(new Date()));
                  }
                }}
                className="text-white btn btn-link"
              >
                TODAY
              </button>
              {/* <button
                className="btn  btn-primary btn-active m-1 btn-sm"
                onClick={() => {
                  if (
                    currentDate.format("YYYY-MM-DD") !==
                    moment().format("YYYY-MM-DD")
                  ) {
                    setCurrentDate(moment());
                  }
                }}
              >
                TODAY
              </button> */}
            </span>
            <span>
              <button
                className="text-white btn btn-link"
                onClick={() => {
                  let dt = add(currentDate, { days: 1 }); //moment(currentDate.format("YYYY-MM-DD")).add(1, "d");
                  onCurrentDateChange(dt);
                }}
              >
                <ArrowCircleRightSharpIcon fontSize="small" />
              </button>

              {/* <button
                className="btn btn-primary btn-active m-1 btn-sm"
                onClick={() => {
                  let dt = moment(currentDate.format("YYYY-MM-DD")).add(1, "d");

                  setCurrentDate(dt);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
            </span>
            <span>
              {/* <DatePicker
                          value={currentDate}
                          defaultValue={currentDate}
                          onChange={(e: any) => {
                            setCurrentDate(e);
                          }}
                        /> */}
            </span>
            <div
              style={{ display: "inline-block" }}
              className="p-1 px-2 text-black"
            >
              <DatePicker
                selected={currentDate}
                onChange={(date: any) => {
                  onCurrentDateChange(date);
                }}
                customInput={
                  <DatePickerInput className="btn-sm text-white hover:text-white hover:bg-gray-700 border border-gray-500 hover:border-gray-400" />
                }
              />

              {/* <input
                type="date"
                onChange={onCurrentDateChange}
                value={moment(currentDate.format("YYYY-MM-DD")).toString()}
              /> */}

              {/* {currentDate.format("ddd MMM DD, YYYY").toUpperCase()} */}
              {/* <input
                onChange={(e) => {}}
                type="text"
                ref={dpRef}
                placeholder="Current Date"
                value={currentDate.format("ddd MMM DD, YYYY").toUpperCase()}
                className="input input-bordered  w-full max-w-xs input-sm input-ghost"
              /> */}
              {/* <DatePicker
                sx={{
                  ".MuiOutlinedInput-root": {
                    fieldset: {
                      borderColor: "#000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000",
                    },
                  },
                }}
                slotProps={{
                  openPickerButton: { sx: { color: "#fff" } },

                  // input: {
                  //   fullWidth: true,
                  //   size: "small",
                  //   color: "success",
                  //   variant: "outlined",
                  //   inputProps: {
                  //     style: {
                  //       color: "#fff",

                  //       textTransform: "uppercase",
                  //       border: "0px",
                  //     },
                  //   },
                  // },
                }}
                format="dddd MMM DD, YYYY"
                value={currentDate}
                onChange={(newValue) => onCurrentDateChange(newValue)}
              /> */}
            </div>
            <div>
              <button
                onClick={() => {
                  toggleEditor();
                }}
                className="btn btn-default btn-sm"
              >
                + NEW BOOKING
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row" style={{ width: "4800px" }}>
          {[...Array(24)].map((x, i) => (
            <div className="scheduler-hour-block" key={i}>
              <div className="hour border-0 border-solid border-b border-gray-300">
                {" "}
                {convert(`${i}:00`)}
              </div>
              <div className="minute-wrapper border-0 border-solid border-gray-100 border">
                {[...Array(4)].map((y, z) => {
                  return (
                    <div
                      key={z}
                      className="minute border-0 border-solid border-r border-gray-300"
                    >
                      {z * 15}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ScheduleHeader;
