import axios from "axios";
import { Organization } from "../types/Orgamization";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { User } from "../types/User";
import { useAuth0 } from "@auth0/auth0-react";

const URL_BASE = "https://dayprism-api.mptran.com/organization";

const getById = async (
  { queryKey }: any,
  token: string
): Promise<Organization> => {
  const [_, params] = queryKey;

  return await (
    await axios.get(`${URL_BASE}/${params.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

const addOrUpdate = async (
  org: Organization,
  token: string
): Promise<Organization> => {
  //   console.log(org);
  return await axios.post(`${URL_BASE}/`, org, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useOrganizationData = (id: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: ["currentOrganization", { id }],
    enabled: id && id.length > 0 ? true : false,
    queryFn: async (queryKey) => {
      const token = await getAccessTokenSilently();
      let data = await getById(queryKey, token);
      return data;
    },
  });
};

export const useMutateOrganizationData = (user: User) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (org: Organization) => {
      const token = await getAccessTokenSilently();
      const data = await addOrUpdate(org, token);
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user", { email: user.email_address }],
      });
    },
  });
};
