const Search = (props: any) => {
  const { showSearch, setShowSearch } = props;
  return (
    <>
      {showSearch && (
        <div className="w-full h-full flex items-start">
          <div className="flex flex-row w-full  content-start justify-items-start justify-end mt-16 pr-14">
            <div className="flex w-1/3">
              <input />
            </div>
            <div>
              <button className="btn btn-link">CLOSE</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Search;
