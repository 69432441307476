import { Link, Outlet, useParams, useSearchParams } from "react-router-dom";
import { useOrganizationData } from "../../api/organization";
import OrgIcon from "../../icon/Org";
import UserGroupIcon from "../../icon/UserGroupIcon";
import UsersIcon from "../../icon/UsersIcon";
import TagIcon from "../../icon/TagIcon";
import Org from "./setting/Org";
import Resource from "./setting/Resource";
import ServiceSetting from "./setting/Service";
import Client from "./setting/Client";
import ServiceEditor from "./setting/ServiceEditor";
import {
  useLoadOrganizationServiceById,
  useLoadOrganizationServices,
} from "../../api/service";
import Service from "../../types/Service";
import ChevronRightIcon from "../../icon/ChevronRightIcon";

function OrganizationResourceList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  let { orgId } = useParams();
  const { data: services } = useLoadOrganizationServices(orgId);

  const loadServiceEditor = (id: string) => {
    setSearchParams({ ...params, action: "update", sId: id });
  };

  return (
    <div className="flex w-full flex-col p-2 px-6">
      <div className="text-gray-400 text-2xl">Services</div>
      <div className="text-gray-300">
        Services are used as part of your booking system.{" "}
      </div>
      <div className="pt-4"></div>
      <div className=" mt-4 bg-gray-100 ">
        {services &&
          services.length > 0 &&
          services.map((s: Service) => {
            return (
              <div
                key={s.id}
                className="my-2 bg-white border border-x-0 border-t-0 hover:bg-gray-50 flex flex-row p-1 pr-4 pl-4 rounded-md"
              >
                <div className="flex flex-col grow">
                  <div className="w-full flex justify-between items-center py-2">
                    <span>{s.name}</span>
                    <span>${s.price}</span>
                  </div>
                  {s.description && s.description != "" && (
                    <div className="w-full max-h-10 overflow-hidden border border-x-0 border-b-0 border-t-gray-100 text-sm text-gray-300">
                      {s.description}
                    </div>
                  )}
                  {/* <div className="flex justify-end text-xs pb-2">
                    <button
                      onClick={() => {
                        loadServiceEditor(s.id);
                      }}
                      className="btn btn-xs btn-link p-0  text-xs  "
                    >
                      EDIT
                    </button>
                  </div> */}
                </div>

                <div className="w-10 flex items-center justify-end">
                  <button
                    onClick={() => {
                      loadServiceEditor(s.id);
                    }}
                    className="btn btn-xs btn-link p-0  text-xs mr-[-8px]"
                  >
                    <ChevronRightIcon />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default function OrganizationResources() {
  let { orgId } = useParams();
  const { data } = useOrganizationData(orgId);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const cmp = searchParams.get("cmp");
  const action = searchParams.get("action");
  //   console.log(cmp);

  const sId = searchParams.get("sId");

  let { data: service } = useLoadOrganizationServiceById(sId ? sId : "");

  return (
    <div className="w-full h-full flex flex-row  bg-base-100">
      <div className="w-[400px] pt-6 h-screen overflow-y-auto ">
        <div className="w-full pt-[135px] relative">
          <div className="flex flex-col px-4 justify-end text-lg bold pb-4  uppercase fixed  bg-base-100 border border-x-0 border-t-0 border-b-1 border-b-gray-200 h-[160px]  top-0 w-[400px]">
            <div className="flex justify-center text-gray-400 pb-4">
              {data?.name}
            </div>
            <div>
              <button
                className="btn btn-block btn-primary btn-md"
                onClick={() => {
                  setSearchParams({ ...params, action: "add", sId: "" });
                }}
              >
                ADD RESOURCE
              </button>
            </div>
          </div>
          <div className="bg-gray-100">
            <OrganizationResourceList />
          </div>
        </div>
      </div>
      <div className="flex grow shadow-2xl z-10">
        <div className="bg-white w-full h-full">
          {action && action == "add" && <ServiceEditor />}
          {action && action == "update" && <ServiceEditor service={service} />}
        </div>
      </div>
    </div>
  );
}
