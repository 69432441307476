import { Outlet, useParams, useSearchParams } from "react-router-dom";

import SideBar from "../nav/SideBar";
import OrgSelection from "../OrgSelection";
import AddUpdateOrganization from "./AddUpdate";
import { useOrganizationData } from "../../api/organization";
import { useGlobalStore } from "../../App";
// import { Organization } from "../../types/Orgamization";
// import { useOrganizationData } from "../../api/organization";

export default function OrganizationHome() {
  const [searchParams] = useSearchParams();
  const user = useGlobalStore((state: any) => state.user);

  const s = searchParams.get("s");
  const a = searchParams.get("a");

  let { orgId } = useParams();
  const { data } = useOrganizationData(orgId);

  return (
    <>
      {orgId && <SideBar />}

      {a && a == "on" && <AddUpdateOrganization />}
      {s && s == "on" && <OrgSelection />}
      <div className="w-full h-full">
        {/* <div className=" right-0 bottom-0  z-20 fixed   mr-4 mb-2 px-2 px-8 rounded-2xl bg-gray-900 text-white shadow text-center text-xs uppercase py-1  overflow-hidden">
          {user?.first_name} {user?.last_name} || {data?.name}
        </div> */}
        <div className="w-full h-full">
          <Outlet />
        </div>
      </div>
    </>
  );
}
