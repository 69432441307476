import { Link, useSearchParams } from "react-router-dom";
import { useGlobalStore } from "../App";
import { UserOrganization } from "../types/UserOrganization";

export default function OrgSelection(props: any) {
  // const { currentUser } = useContext(AppContext);
  //   const currentUser: any = useStore(store, (state) => state.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useGlobalStore((state: any) => state.user);
  const params = Object.fromEntries([...searchParams]);

  const close = () => {
    setSearchParams({ ...params, s: "" });
  };

  return (
    <div
      className="w-screen h-screen fixed  p-2 z-50"
      style={{ background: "rgba(0, 0, 0, .75)" }}
    >
      <div className="w-[350px] bg-gray-50 shadow-xl flex flex-col h-full border border-l-0 border-r-1 border-gray-200 border-y-0  text-black">
        <div className="flex justify-between items-center bg-gray-200 text-black shadow-xs pl-2">
          <span className="text-xs">SELECT AN ORGANIZATION</span>
          <span>
            <button className="btn btn-link no-animation" onClick={close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </span>
        </div>

        {user &&
          (!user.user_organizations || user.user_organizations.length == 0) && (
            <div className="p-4 flex justify-center pt-6 text-gray-400">
              <article className="text-sm">
                You do not have any organization. Create a new organization to
                get started. If you wish to have access to an existing
                organization, please contact the organization's owner.
              </article>{" "}
            </div>
          )}

        {user &&
          user.user_organizations &&
          user.user_organizations.length > 0 && (
            <div className="p-1 flex flex-col ">
              {user.user_organizations.map((x: UserOrganization) => (
                <div
                  className="text-sm uppercase text-gray-500 pt-2 pb-1 hover:bg-gray-200 pl-2 mb-1 border-gray-200 border border-x-0 border-t-0 border-b-1 rounded rounded-sm"
                  key={x.id}
                >
                  <Link to={`/prism/${x.organization?.id}`}>
                    {x.organization?.name}
                  </Link>
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
}
