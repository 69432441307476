import { useParams, useSearchParams } from "react-router-dom";
import { useLoadOrganizationServices } from "../../../api/service";
import Service from "../../../types/Service";
import EditIcon from "../../../icon/EditIcon";

export default function ServiceSetting() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  let { orgId } = useParams();
  const { data: services } = useLoadOrganizationServices(orgId);

  const loadServiceEditor = (id: string) => {
    setSearchParams({ ...params, action: "update", sId: id });
  };

  return (
    <div className="flex w-full flex-col p-2 px-6">
      <div className="text-gray-400 text-2xl">Services</div>
      <div className="text-gray-300">
        Services are used as part of your booking system.{" "}
      </div>
      <div className="pt-4">
        <button
          className="btn btn-block btn-default btn-md"
          onClick={() => {
            setSearchParams({ ...params, action: "add", sId: "" });
          }}
        >
          CREATE NEW SERVICE
        </button>
      </div>
      <div className=" mt-4 bg-gray-100 ">
        {services &&
          services.length > 0 &&
          services.map((s: Service) => {
            return (
              <div
                key={s.id}
                className="my-2 bg-white border border-x-0 border-t-0 hover:bg-gray-50 flex flex-col p-1 pr-4 pl-4 rounded-md"
              >
                <div className="w-full flex justify-between items-center py-2">
                  <span>{s.name}</span>
                  <span>${s.price}</span>
                </div>
                {s.description && s.description != "" && (
                  <div className="w-full max-h-10 overflow-hidden border border-x-0 border-b-0 border-t-gray-100 text-sm text-gray-300">
                    {s.description}
                  </div>
                )}
                <div className="flex justify-end text-xs pb-2">
                  <button
                    onClick={() => {
                      loadServiceEditor(s.id);
                    }}
                    className="btn btn-xs btn-link rounded-sm text-xs mr-[-10px] "
                  >
                    <EditIcon />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
