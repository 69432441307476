import { Link, useSearchParams } from "react-router-dom";
import { useGlobalStore } from "../../App";
import { UserOrganization } from "../../types/UserOrganization";
import ClickAwayListener from "react-click-away-listener";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { User } from "../../types/User";
import { useLoadUserData, useMutateUser } from "../../api/user";

export default function OrganizationMain() {
  const { logout, user } = useAuth0();
  const { mutate } = useMutateUser();

  const userStore = useGlobalStore((state: any) => state.user);
  const updateUser = useGlobalStore((state: any) => state.updateUser);

  // console.log(user);
  const { data } = useLoadUserData(user && user.email ? user.email : "");

  const [searchParams, setSearchParams] = useSearchParams();

  const params = Object.fromEntries([...searchParams]);

  const toggleAddUpdateSelection = () => {
    setSearchParams({ ...params, a: "on" });
  };

  const [showOrgSelection, setShowOrgSelection] = useState(false);
  const handleClickAway = () => {
    setShowOrgSelection(false);
  };

  useEffect(() => {
    if (data) {
      updateUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      let _newUser: User = {
        first_name: user?.given_name,
        last_name: user?.family_name,
        email_address: user?.email,
        email_verified: user?.email_verified,
        picture: user?.picture,
        sub: user?.sub,
        nickname: user?.nickname,
      };
      mutate(_newUser);
    }
  }, [user]);

  return (
    <div className="flex w-full h-full flex-col justify-center items-center">
      <div className="fixed right-0 top-0 p-2">
        <button
          onClick={() => {
            logout();
          }}
          className="btn btn-default mr-2"
        >
          Logout
        </button>
      </div>
      <div className="text-gray-600 text-2xl">
        Welcome! {userStore?.first_name} {userStore?.last_name}{" "}
      </div>

      <div className="pt-4 w-3/5 text-center">
        Please select an organization or create a new organization to start. If
        you wish to access an existing organization, please contact the
        organization's administrator or owner.
      </div>
      <div className="pt-4 flex flex-row">
        <div className="mt-4">
          <div className="card w-96   ">
            <figure className="px-10 pt-10">
              <img src="/org.png" className="w-20" />
            </figure>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="card-body items-center text-center">
                <button
                  id="dropdownUsersButton"
                  data-dropdown-toggle="dropdownUsers"
                  data-dropdown-placement="bottom"
                  className="text-black rounded-sm text-sm px-5 py-2.5 text-center inline-flex items-center "
                  type="button"
                  onClick={() => {
                    setShowOrgSelection(!showOrgSelection);
                  }}
                >
                  SELECT AN ORGANIZATION
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {showOrgSelection && (
                  <div
                    id="dropdownUsers"
                    className="z-10 absolute bg-white border border-gray-300 p-1 py-2  shadow-xl  rounded-2xl w-80 "
                  >
                    <ul
                      className="h-48 py-2 overflow-y-auto bg-white rounded"
                      aria-labelledby="dropdownUsersButton"
                    >
                      {userStore &&
                        userStore.user_organizations &&
                        userStore.user_organizations.map(
                          (x: UserOrganization) => (
                            <Link
                              key={x.id}
                              to={`/prism/${x.organization?.id}`}
                            >
                              <li className="text-black flex justify-start py-2 px-4 hover:bg-gray-100">
                                {x.organization?.name}
                              </li>
                            </Link>
                          )
                        )}
                    </ul>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </div>
        <div className="mt-4 flex items-center justify-end">
          <button
            className="btn btn-primary w-full"
            onClick={toggleAddUpdateSelection}
          >
            CREATE NEW ORGANIZATION
          </button>
        </div>
      </div>
    </div>
  );
}
