import { forwardRef } from "react";

const DatePickerInput = forwardRef(
  ({ value, onClick, className }: any, ref: any) => (
    <button
      className={`btn ${className} w-full btn-outline`}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  )
);

export default DatePickerInput;
