// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}


.react-datepicker__input-container input:focus,
.react-datepicker__input-container input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 2px;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
}

.react-datepicker__input-container input {
  font-size: 0.875rem;
  line-height: 2rem;
  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));
  flex-shrink: 1;
  appearance: none;

  padding-left: 1rem;
  padding-right: 1rem;

  border-radius: var(--rounded-btn, 0.5rem);
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));

}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;;AAGA;;EAEE,gBAAgB;EAChB,wDAAwD;EACxD,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,yDAAyD;AAC3D;;AAEA;EAIE,mBAAmB;EACnB,iBAAiB;EAEjB,wDAAwD;EACxD,cAAc;EAGd,gBAAgB;;EAEhB,kBAAkB;EAClB,mBAAmB;;EAEnB,yCAAyC;EACzC,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,6EAA6E;;AAE/E","sourcesContent":["html,\nbody {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\n\n.react-datepicker__input-container input:focus,\n.react-datepicker__input-container input:focus-within {\n  box-shadow: none;\n  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));\n  outline-style: solid;\n  outline-width: 2px;\n  outline-offset: 2px;\n  outline-color: var(--fallback-bc, oklch(var(--bc) / 0.2));\n}\n\n.react-datepicker__input-container input {\n\n  padding-left: 0.75rem;\n  padding-right: 0.75rem;\n  font-size: 0.875rem;\n  line-height: 2rem;\n  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));\n  border-color: var(--fallback-bc, oklch(var(--bc) / 0.2));\n  flex-shrink: 1;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n\n  padding-left: 1rem;\n  padding-right: 1rem;\n\n  border-radius: var(--rounded-btn, 0.5rem);\n  border-width: 1px;\n  border-color: transparent;\n  --tw-bg-opacity: 1;\n  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
