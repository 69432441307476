import axios from "axios";
import { User } from "../types/User";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

const URL_BASE = "https://dayprism-api.mptran.com/user";
// const headers = { "Content-type": "application/json" };

// export const getUser = async ({ queryKey }: any): Promise<User> => {
//   const [_, params] = queryKey;
//   console.log(`Email is: ${params.email}`);
//   return await (
//     await axios.get(`${URL_BASE}/?email=${params.email}`)
//   ).data;
// };

const fetchUser = async ({ queryKey }: any, token: string): Promise<User> => {
  const [key] = queryKey;

  // console.log(`Email is: ${params.email}`);
  return await (
    await axios.get(`${URL_BASE}/?email=${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

const add = async (org: User, token: string): Promise<User> => {
  //   console.log(org);

  return await axios.post(`${URL_BASE}/`, org, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
// export const createUser = async (user: Omit<User, "id">): Promise<User> => {
//   const body = JSON.stringify(user);
//   const method = "POST";
//   return await (await fetch(URL_BASE, { body, method, headers })).json();
// };

// export const editUser = async (user: User): Promise<User> => {
//   const body = JSON.stringify(user);
//   const method = "PUT";
//   return await (
//     await fetch(`${URL_BASE}/${user.id}`, { body, method, headers })
//   ).json();
// };

// export const deleteUser = async (id: number): Promise<number> => {
//   const method = "DELETE";
//   await fetch(`${URL_BASE}/${id}`, { method });
//   return id;
// };

export const useLoadUserData = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [email],
    enabled: email && email.length > 0 ? true : false,
    queryFn: async (queryKey) => {
      const token = await getAccessTokenSilently();

      const data = await fetchUser(queryKey, token);
      return data;
    },
  });
};

export const useMutateUser = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (org: User) => {
      const token = await getAccessTokenSilently();

      let data = await add(org, token);
      return data;
    },
    onSuccess: async (result: any) => {
      const token = await getAccessTokenSilently();
      fetchUser({ queryKey: [result.data.email_address] }, token).then(
        (user: any) => {
          queryClient.setQueryData([result.data.email_address], user); //
        }
      );
    },
  });
};
