import { useForm, SubmitHandler } from "react-hook-form";
import { Organization } from "../../types/Orgamization";
import states from "../../states.json";
import { useMutateOrganizationData } from "../../api/organization";
import { useGlobalStore } from "../../App";
import { useSearchParams } from "react-router-dom";

export default function AddUpdateOrganization() {
  const labelStyle = "pb-1 pl-1 uppercase";
  const inputStyle = "pb-2";

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const close = () => {
    setSearchParams({ ...params, a: "" });
  };

  const user = useGlobalStore((state: any) => state.user);

  const { mutate } = useMutateOrganizationData(user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Organization>();
  const onSubmit: SubmitHandler<Organization> = (data) => {
    data.owner_id = user.id;
    mutate(data);
  };

  return (
    <div className="w-screen flex justify-end h-screen bg-black p-2 fixed left-0 top-0 z-20 bg-opacity-75">
      <div className="w-[600px] bg-white shadow-xl opacity-100  h-full  text-black">
        <div className="flex justify-between items-center bg-gray-200 text-black pl-2">
          <span>ADD ORGANIZATION</span>
          <span>
            <button className="btn btn-link no-animation" onClick={close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="form-controls">
          <div className="text-gray-400 px-8 py-4">
            Please enter information below to create a new organization. If you
            wish to have access to an existing client, please contact its owner.
          </div>
          <div className="flex flex-col px-6 pt-4 text-black">
            <div className={labelStyle}>Organization Name</div>
            <div className={inputStyle}>
              <input
                {...register("name", {
                  required: true,
                  minLength: 3,
                  maxLength: 200,
                })}
                type="text"
                placeholder="Organization Name"
                className="input input-bordered w-full"
              />
            </div>
            <div>
              {errors.name?.type == "required" && (
                <p className="text-error">Name is required.</p>
              )}
            </div>
            <div className={labelStyle}>Address</div>
            <div className={inputStyle}>
              <input
                {...register("address", {
                  required: false,
                  minLength: 3,
                  maxLength: 500,
                })}
                type="text"
                placeholder="Address"
                className="input input-bordered w-full"
              />
            </div>
            <div className={labelStyle}>Address 2</div>
            <div className={inputStyle}>
              <input
                {...register("address_2", {
                  required: false,
                  minLength: 3,
                  maxLength: 500,
                })}
                type="text"
                placeholder="Address (line 2)"
                className="input input-bordered w-full"
              />
            </div>
            <div className={labelStyle}>City, State Zip</div>
            <div className={`${inputStyle} flex flex-row`}>
              <div className="pr-1 grow">
                <input
                  {...register("city", {
                    required: false,
                    minLength: 3,
                    maxLength: 500,
                  })}
                  type="text"
                  placeholder="City"
                  className="input input-bordered w-full"
                />
              </div>
              <div className="pr-1 w-24">
                <select
                  className="select select-bordered w-full max-w-xs"
                  {...register("state", {
                    required: false,
                    minLength: 1,
                    maxLength: 200,
                  })}
                >
                  <option value="">State</option>
                  {states &&
                    states.map((s: any) => {
                      return (
                        <option key={s.abbreviation} value={s.abbreviation}>
                          {s.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="flex w-26">
                <input
                  {...register("zip", {
                    required: false,
                    minLength: 3,
                    maxLength: 20,
                  })}
                  type="text"
                  placeholder="Zip"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button type="submit" className="btn btn-primary btn-md px-6">
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
