import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Auth0Provider } from "@auth0/auth0-react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { AppStateProvider } from "./AppStateProvider.tsx";
// import { AppContextProvider } from "./AppContextProvider.tsx";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 120000,
    },
  },
});
ReactDOM.createRoot(document.getElementById("root")!).render(
  <Auth0Provider
    domain="dev-taweml4hmtia4k4k.us.auth0.com"
    clientId="sj7a6dbRP6giL95bcICjtyolEBq1V0z2"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </Auth0Provider>
);
