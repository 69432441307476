const ResourcePanel = (props: any) => {
  const { resources } = props;
  return (
    <div className="scheduler-resource-panel  shadow-2xl border border-b-0 border-l-0 bg-grey-100  border-t-0  z-20">
      <div
        className="sticky bg-white border-0 border-b-2  border-solid border-b-gray-600 shadow-sm"
        style={{
          height: "98px",
          top: 0,
        }}
      >
        <div className="scheduler-resource-name  bg-gray-200">
          RESOURCE NAME
        </div>
      </div>
      <div className="scheduler-resource-wrappter bg-gray-50">
        {resources.map((y: any, z: any) => {
          return (
            <div
              key={z}
              className="scheduler-row scheduler-row-item border border-solid border-gray-300 shadow-2xl border-r-0 bg-gray-100 border-t-0 border-l-0 border-r-0 flex justify-end text-sm"
            >
              {resources[z].first_name} {resources[z].last_name}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ResourcePanel;
