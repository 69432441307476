import axios from "axios";
import Service from "../types/Service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

const URL_BASE = "https://dayprism-api.mptran.com/service";

const getById = async (
  { queryKey }: any,
  token: string
): Promise<Service | undefined> => {
  const [key] = queryKey;

  if (!key || key == "") {
    return;
  }
  return await (
    await axios.get(`${URL_BASE}/${key}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

const getAll = async ({ queryKey }: any, token: string): Promise<Service[]> => {
  const [_, params] = queryKey;
  return await (
    await axios.get(`${URL_BASE}/?orgId=${params.orgId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
};

const add = async (svc: Service, token: string) => {
  //   console.log(org);
  return await axios.post(`${URL_BASE}/`, svc, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const deleteSvc = async (key: string, token: string) => {
  if (!key || key == "") {
    return;
  }
  return await await axios.delete(`${URL_BASE}/${key}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useLoadOrganizationServiceById = (id: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: [id],
    enabled: id && id.length > 0 ? true : false,
    queryFn: async (queryKey) => {
      const token = await getAccessTokenSilently();
      const data = await getById(queryKey, token);
      return data;
    },
  });
};

export const useLoadOrganizationServices = (orgId: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    enabled: orgId && orgId.length > 0 ? true : false,
    queryKey: ["organizationService", { orgId }],
    queryFn: async (queryKey) => {
      const token = await getAccessTokenSilently();
      const data = await getAll(queryKey, token);
      return data;
    },
  });
};

export const useMutateOrganizationService = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (s: Service) => {
      const token = await getAccessTokenSilently();

      const data = await add(s, token);
      return data;
    },
    onSuccess: async (result) => {
      let oId = result.data.organization_id;

      let key = ["organizationService", { orgId: oId }];
      const token = await getAccessTokenSilently();
      //invalidate any existing service data
      queryClient.invalidateQueries({ queryKey: [result.data.id] });

      getAll({ queryKey: key }, token).then(async (service: any) => {
        queryClient.setQueryData(key, service); //
      });
    },
  });
};

export const useDeleteOrganizationService = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (id: string) => {
      const token = await getAccessTokenSilently();
      const data = await deleteSvc(id, token);
      return data;
    },
    onSuccess: async (result) => {
      if (result) {
        let key = [
          "organizationService",
          { orgId: result.data.organization_id },
        ];
        const token = await getAccessTokenSilently();
        //invalidate any existing service data
        queryClient.invalidateQueries({ queryKey: [result.data.id] });

        getAll({ queryKey: key }, token).then((service: any) => {
          queryClient.setQueryData(key, service); //
        });
      }
    },
  });
};
