import { useOrganizationData } from "../../api/organization";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useGlobalStore } from "../../App";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import OrgIcon from "../../icon/Org";
import ChevronUpDownIcon from "../../icon/ChevronUpDownIcon";
import ChevronDoubleDownIcon from "../../icon/ChevronDoubleDownIcon";
import PlusIcon from "../../icon/PlusIcon";
import Square2x2Icon from "../../icon/Square2x2Icon";
import ArrowLeftOnRectangleIcon from "../../icon/ArrowLeftOnRectangleIcon";
import UserIcon from "../../icon/UserIcon";
import VerticalEllipsisIcon from "../../icon/VerticalElipsis";
import Close from "../../icon/Close";
import ArrowRightLeftIcon from "../../icon/ArrowRightLeftIcon";
import Bars3Icon from "../../icon/Bars3Icon";
import SchedulerIcon from "../../icon/SchedulerIcon";
import HomeIcon from "../../icon/HomeIcon";
import AppBuilderIcon from "../../icon/AppBuilderIcon";
import SettingsIcon from "../../icon/SettingsIcon";
import CloseIcon from "../../icon/Close";
import TagIcon from "../../icon/TagIcon";
import UsersIcon from "../../icon/UsersIcon";
import UserGroupIcon from "../../icon/UserGroupIcon";

const MenuItem = (props: any) => {
  return (
    <li className="my-1 bg-gray-100 rounded-full p-2 text-black border border-solid border-gray-300 z-10">
      {props.children}
    </li>
  );
};

const MenuNoBorder = (props: any) => {
  return <ul className="flex flex-row items-center px-2">{props.children}</ul>;
};
const MenuItemNoBorder = (props: any) => {
  return <li className="mx-2">{props.children}</li>;
};

export const Logout = (props: any) => {
  const { setLogout } = props;
  const { logout } = useAuth0();

  return (
    <div className="flex w-screen h-screen bg-black fixed left-0 top-0 z-40 justify-center items-center opacity-75">
      <div className="flex flex-col bg-white opacity-100 p-4 rounded rounded-sm justify-center items-center">
        <span>ARE YOU SURE YOU WANT TO LOGOUT?</span>
        <div className="flex flex-row pt-4">
          <button
            onClick={() => {
              setLogout(false);
            }}
            className="btn btn-md btn-default mr-2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
            className="btn btn-md btn-warning"
          >
            LOGOUT
          </button>
        </div>
      </div>
    </div>
  );
};

const AppLauncher = (props: any) => {
  const orgId = props.orgId;
  const toggleAppLauncher = props.toggleAppLauncher;
  const itemClass = "p-2 flex flex-col items-center py-6";
  return (
    <>
      <div className="flex fixed top-0 left-0 w-screen h-screen justify-center items-end">
        <div className="mb-[70px]  overflow-hidden min-w-[600px] min-h-[100px] shadow-2xl rounded-md flex flex-col bg-gray-800 mx-20 relative">
          <div className="absolute right-0">
            <button
              className="btn btn-link text-white p-0 pr-2"
              onClick={() => {
                toggleAppLauncher();
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="overflow-auto max-w-[100vw]  p-6 flex justify-start text-sm uppercase flex-wrap">
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/day`}>
                <HomeIcon />
              </Link>
              <span>My Day</span>
            </div>
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/scheduler`}>
                <SchedulerIcon />
              </Link>
              <span>Scheduler</span>
            </div>
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/builder`}>
                <AppBuilderIcon />
              </Link>
              <span>App Builder</span>
            </div>
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/services`}>
                <TagIcon />
              </Link>
              <span>Pricing & Services</span>
            </div>
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/resources`}>
                <UsersIcon />
              </Link>
              <span>Resources & Users</span>
            </div>
            <div className={itemClass}>
              <Link to={`/prism/${orgId}/services`}>
                <UserGroupIcon />
              </Link>
              <span>Clients</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default function SideBar() {
  const [searchParams, setSearchParams] = useSearchParams();

  let { orgId } = useParams();
  const params = Object.fromEntries([...searchParams]);

  // const s = searchParams.get("s");
  // const a = searchParams.get("a");
  const hm = searchParams.get("hm");
  const apl = searchParams.get("apl");

  const user = useGlobalStore((state: any) => state.user);

  const orgData = useOrganizationData(orgId);

  // const toggleOrgSelection = () => {
  //   setSearchParams({ ...params, s: !s || s == "" ? "on" : "" });
  // };

  // const toggleAddUpdateSelection = () => {
  //   setSearchParams({ ...params, a: !a || a == "" ? "on" : "" });
  // };

  const toggleHideMenu = () => {
    setSearchParams({ ...params, hm: hm && hm == "1" ? "" : "1" });
  };
  const toggleAppLauncher = () => {
    setSearchParams({ ...params, apl: apl && apl == "on" ? "" : "on" });
  };
  const [logout, setLogout] = useState(false);
  return (
    <>
      {logout && <Logout setLogout={setLogout} />}
      <div
        className={`w-screen h-[60px] p-1  fixed  text-black flex flex-row ${hm && hm == "1" ? "justify-start" : "justify-center"} bottom-0 z-20 pointer-events-none`}
      >
        {hm && hm == "1" && (
          <div className="pointer-events-auto">
            <button
              className="btn btn-primary btn-md"
              onClick={() => {
                toggleHideMenu();
              }}
            >
              <span className="tooltip tooltip-top" tip-data="SHOW MENU">
                <Bars3Icon />
              </span>
            </button>
          </div>
        )}

        {!hm && (
          <div
            className={`pointer-events-auto bg-black text-white flex flex-row rounded-full py-4 px-2  ${hm && hm == "1" ? "ml-[-470px]" : ""}`}
          >
            {apl && apl == "on" && (
              <AppLauncher
                toggleAppLauncher={toggleAppLauncher}
                orgId={orgData.data?.id}
              />
            )}

            <div className="text-black flex flex-row text-white">
              {user && (
                <MenuNoBorder>
                  <MenuItemNoBorder>
                    <Link
                      to="/prism"
                      className="tooltip tooltip-top uppercase text-xs mt-1"
                      data-tip={`Exit ${orgData.data?.name}`}
                    >
                      <ArrowLeftOnRectangleIcon />
                    </Link>
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <button
                      onClick={() => {
                        toggleAppLauncher();
                      }}
                      className="btn btn-link text-white p-0"
                    >
                      <span className="tooltip tooltip-top" data-tip="APPS">
                        <Square2x2Icon />
                      </span>
                    </button>
                  </MenuItemNoBorder>

                  <MenuItemNoBorder>
                    <VerticalEllipsisIcon />
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <UserIcon />
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <span className="uppercase text-sm">
                      {user.first_name} {user.last_name}
                    </span>
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <ChevronUpDownIcon />
                  </MenuItemNoBorder>

                  <MenuItemNoBorder>
                    <OrgIcon />
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <span className="uppercase text-sm">
                      {orgData.data?.name}
                    </span>
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <VerticalEllipsisIcon />
                  </MenuItemNoBorder>
                  <MenuItemNoBorder>
                    <button
                      onClick={() => {
                        toggleHideMenu();
                      }}
                      className="btn btn-link text-white btn-sm p-0 m-0 tooltip tooltip-top text-xs uppercase"
                      data-tip="Toggle Menu"
                    >
                      <ArrowRightLeftIcon />
                    </button>
                  </MenuItemNoBorder>
                </MenuNoBorder>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
